import { styled } from "@mui/system";

const PageWrapBreakout = styled("div")(({ theme }) => ({
  "&": {
    width: `calc(100% + ${theme.spacing(4)})`,
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% + ${theme.spacing(30)})`,
      marginLeft: theme.spacing(-15),
      marginRight: theme.spacing(-15),
    },
    [theme.breakpoints.up("md")]: {
      width: `calc(100% + ${theme.spacing(40)})`,
      marginLeft: theme.spacing(-20),
      marginRight: theme.spacing(-20),
    },
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% + ${theme.spacing(50)})`,
      marginLeft: theme.spacing(-25),
      marginRight: theme.spacing(-25),
    },
    [theme.breakpoints.up("xl")]: {
      width: `calc(100% + ${theme.spacing(70)})`,
      marginLeft: theme.spacing(-35),
      marginRight: theme.spacing(-35),
    },
  },
}));

export default PageWrapBreakout;
