import { useMediaQuery, useTheme } from "@mui/material";
import { useMemo } from "react";
import HeroA from "../../views/Hero/HeroA/HeroA";
import { buildImageData } from "../../../utils/image/buildImageData";
import { buildLink } from "../../../utils/link/buildLink";
import { isNil } from "lodash";

type CMSHeroAProps = {
  data: GatsbyTypes.LandingPageQuery["prismicLandingPage"]["data"]["hero"];
};

const CMSHeroA = ({ data }: CMSHeroAProps) => {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const slides = useMemo(
    () =>
      data.map(({ image, mobile_image, button_link, button_label }) => ({
        imageData: isDesktop
          ? buildImageData(image)
          : buildImageData(mobile_image),
        buttonLink: buildLink(button_link),
        buttonLabel: button_label,
      })),
    [data, isDesktop]
  );

  if (isNil(slides)) {
    return null;
  }

  return <HeroA slides={slides} />;
};

export default CMSHeroA;
