import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import { Box } from "@mui/material";
import Image from "../../../elements/Image/Image";
import { ImageData } from "../../../../utils/image/buildImageData";
import CircularButton from "../../../elements/button/CircularButton";
import { isNil } from "lodash";

export type HeroAProps = {
  slides: SlideContent[];
};

export type SlideContent = {
  imageData: ImageData;
  buttonLink: string;
  buttonLabel: string;
};

const SlideContent = ({ imageData, buttonLabel, buttonLink }: SlideContent) => {
  return (
    <>
      {!isNil(buttonLink) && !isNil(buttonLabel) && (
        <CircularButton
          sx={{
            position: "absolute",
            display: {
              sm: "none",
            },
            top: (theme) => theme.spacing(12),
            left: (theme) => theme.spacing(2),
            zIndex: 1,
          }}
          href={buttonLink}
        >
          {buttonLabel}
        </CircularButton>
      )}
      <Image
        alt="image"
        image={imageData.image}
        style={{ height: "100%", display: "block" }}
        imgStyle={{ minHeight: "100%" }}
      />
    </>
  );
};

const HeroA = ({ slides }: HeroAProps) => {
  return (
    <Box sx={{ height: "100vh", overflow: "hidden" }}>
      {slides.length === 1 ? (
        <SlideContent
          imageData={slides[0].imageData}
          buttonLabel={slides[0].buttonLabel}
          buttonLink={slides[0].buttonLink}
        />
      ) : (
        <Swiper
          modules={[EffectFade, Autoplay]}
          effect="fade"
          spaceBetween={0}
          slidesPerView={1}
          speed={2000}
          autoplay={{
            delay: 5000,
          }}
          style={{ height: "100%" }}
        >
          {slides.map(({ imageData, buttonLabel, buttonLink }, index) => {
            return (
              <SwiperSlide key={index}>
                <SlideContent
                  imageData={imageData}
                  buttonLabel={buttonLabel}
                  buttonLink={buttonLink}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </Box>
  );
};

export default HeroA;
