import { isNil } from "lodash";

export const buildLink = (
  prismicLink: GatsbyTypes.LandingPageQuery["prismicLandingPage"]["data"]["hero"][0]["button_link"]
) => {
  if (!isNil(prismicLink.document?.url)) {
    return prismicLink.document?.url;
  }

  if (!isNil(prismicLink.localFile)) {
    return prismicLink.localFile.publicURL;
  }

  return prismicLink.url;
};
