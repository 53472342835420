/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

type SeoProps = {
  description?: string;
  lang?: string;
  title: string;
};

const Seo = ({
  description = "",
  lang = "de",
  title = "Cubical",
}: SeoProps) => {
  const { prismicLayout } = useStaticQuery<GatsbyTypes.SEOQueryQuery>(
    graphql`
      query SEOQuery {
        prismicLayout {
          data {
            logo {
              localFile {
                publicURL
              }
              alt
            }
            company_description {
              text
            }
            contact_information {
              text
            }
          }
        }
      }
    `
  );

  const metaDescription =
    description || prismicLayout.data.company_description.text;

  const logo = prismicLayout.data.logo.localFile.publicURL;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: logo,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `theme-color`,
          content: "#1995d2",
        },
      ]}
      link={[
        {
          rel: "apple-touch-icon",
          sizes: "180x180",
          href: logo,
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "32x32",
          href: logo,
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "16x16",
          href: logo,
        },
        {
          rel: "mask-icon",
          color: "#1995d2",
          href: logo,
        },
      ]}
    />
  );
};

export default Seo;
