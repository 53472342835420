import { Grid } from "@mui/material";
import React, { Children, ReactElement } from "react";

type PageSpacerWrapperProps = {
  children: ReactElement[] | ReactElement;
};

export const pageSpacerRowSpacing = { xs: 6, sm: 8, xl: 10 };

const PageSpacerWrapper = ({ children }: PageSpacerWrapperProps) => {
  return (
    <Grid
      container
      rowSpacing={pageSpacerRowSpacing}
      sx={{ paddingBottom: pageSpacerRowSpacing, scrollBehavior: "smooth" }}
    >
      {Children.map(children, (child) => (
        <Grid item container>
          {child}
        </Grid>
      ))}
    </Grid>
  );
};

export default PageSpacerWrapper;
