import { motion } from "framer-motion";
import { ReactNode } from "react";

type PageTransitionWrapperProps = {
  pageId: string;
  children: ReactNode;
};

const PageTransitionWrapper = ({
  pageId,
  children,
}: PageTransitionWrapperProps) => {
  return (
    <motion.div
      key={pageId}
      initial={{
        opacity: 0,
        y: -10,
      }}
      animate={{
        opacity: 1,
        y: 0,
      }}
      exit={{
        opacity: 0,
        y: 10,
      }}
    >
      {children}
    </motion.div>
  );
};

export default PageTransitionWrapper;
