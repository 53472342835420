import Button, { ButtonProps } from "./Button";

export type CircularButtonProps = Pick<ButtonProps, "children" | "sx" | "href">;

const CircularButton = ({ children, sx, href }: CircularButtonProps) => {
  return (
    <Button
      sx={{
        width: "120px",
        height: "120px",
        borderRadius: "50%",
        textTransform: "none",
        ...sx,
      }}
      href={href}
    >
      {children}
    </Button>
  );
};

export default CircularButton;
